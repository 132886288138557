<script setup>
const showSignInMenu = ref(false);
const navigation = [
	{
		ID: "0",
		url: "/news/",
		title: "News"
	},
	{
		ID: "1",
		url: "/platform/",
		title: "Platform"
	},
	{
		ID: "2",
		url: "/plans/",
		title: "Pricing"
	},
	{
		ID: "3",
		url: "/#",
		title: "Trading Guides",
		children: [
			{
				ID: "A",
				title: "Trading 101",
				url: "#",
				children: [
					{
						ID: `0`,
						url: "/blog/",
						title: "Blog"
					},
					{
						ID: `1`,
						url: "/understanding-level-2-quotes-infographic/",
						title: "Level 2 Quotes"
					},
					{
						ID: `2`,
						url: "/40-trading-terms-beginners-infographic/",
						title: "Trading Terminology"
					},
					{
						ID: `3`,
						url: "/stop-limit-order/",
						title: "Stop Limit Orders"
					},
					{
						ID: `4`,
						url: "/relative-volume/",
						title: "Relative Volume"
					},
					{
						ID: `5`,
						url: "/market-makers/",
						title: "Market Makers Guide"
					},
					{
						ID: `6`,
						url: "/margin-rate/",
						title: "Margin Rate Explainer"
					},
					{
						ID: `7`,
						url: "/pivot-points/",
						title: "Pivot Points"
					}
				]
			},
			{
				ID: "B",
				title: "beginner guides",
				url: "#",
				children: [
					{
						ID: `1`,
						url: "/how-to-day-trade-for-a-living/",
						title: "How to Day Trade for a Living"
					},
					{
						ID: `2`,
						url: "/5-things-to-look-for-before-investing/",
						title: "What to Look for Before Investing"
					},
					{
						ID: `3`,
						url: "/three-reasons-i-avoid-stocks-under-1/",
						title: "Short Selling"
					},
					{
						ID: `4`,
						url: "/relative-volume/",
						title: "Day Trading Essentials"
					},
					{
						ID: `5`,
						url: "/how-to-buy-stocks/",
						title: "How To Buy Stocks"
					},
					{
						ID: `6`,
						url: "/understand-stock-splits-infographic/",
						title: "Stock Split Guide"
					},
					{
						ID: `7`,
						url: "/trading-psychology/",
						title: "Psychology of Trading"
					},
					{
						ID: `8`,
						url: "/technical-analysis/",
						title: "Technical Analysis"
					},
					{
						ID: `9`,
						url: "/fundamental-analysis/",
						title: "Fundamental Analysis"
					}
				]
			},
			{
				ID: "C",
				title: "day trading",
				url: "#",
				children: [
					{
						ID: `1`,
						url: "/stocks-to-watch/",
						title: "Stocks to Watch"
					},
					{
						ID: `2`,
						url: "/ai-penny-stocks/",
						title: "AI Penny Stocks"
					},
					{
						ID: `3`,
						url: "/best-screeners/stock-screener-for-day-trading/",
						title: "Day Trading Scanners"
					},
					{
						ID: `4`,
						url: "/paper-trading/",
						title: "Paper Trading Guide"
					},
					{
						ID: `5`,
						url: "/low-float-stocks/",
						title: "Low Float Stocks"
					},
					{
						ID: `6`,
						url: "/pattern-day-trader-avoid-classified-one/",
						title: "Pattern Trader Rule"
					}
				]
			},
			{
				ID: "D",
				title: "How to's",
				url: "#",
				children: [
					{
						ID: `1`,
						url: "/how-to-read-stock-charts/",
						title: "How to Read Stock Charts"
					},
					{
						ID: `2`,
						url: "/create-trading-plan/",
						title: "Craft Your Trading Plan"
					},
					{
						ID: `3`,
						url: "/how-to-use-stock-scanners/",
						title: "Stock Scanners"
					},
					{
						ID: `4`,
						url: "/create-stock-watchlist/",
						title: "Building a Watchlist"
					}
				]
			}
		]
	},

	{
		ID: "4",
		url: "/#",
		title: "Resources",
		children: [
			{
				ID: "A",
				url: "#",
				title: "Strategies",
				children: [
					{ ID: "1", url: "/short-float/", title: "Short Float" },
					{
						ID: "2",
						url: "/trading-strategies/",
						title: "Trading Strategies"
					},
					{ ID: "3", url: "/trailing-stop-order/", title: "Using a Trailing Stop" },
					{ ID: "4", url: "/gap-trading-strategy/", title: "Trading the Gap" },
					{ ID: "5", url: "/trend-trading-strategy/", title: "Trend Trading" },
					{ ID: "6", url: "/leverage-trading/", title: "Trading with Leverage" },
					{ ID: "7", url: "/macd-for-day-trading/", title: "MACD for Day Trading" }
				]
			},
			{
				ID: "B",
				url: "#",
				title: "Trading Tools",
				children: [
					{ ID: "1", url: "/day-trading-setups/", title: "Day Trading Setup" },
					{
						ID: "2",
						url: "/best-trading-computers-and-laptops/",
						title: "Computers for Trading"
					},
					{
						ID: "3",
						url: "/best-screeners/stock-screener-for-swing-trading/",
						title: "Swing Trading Screeners"
					},
					{
						ID: "4",
						url: "/best-screeners/option-stock-screener/",
						title: "Options Screeners"
					}
				]
			},
			{
				ID: "C",
				url: "#",
				title: "Patterns",
				children: [
					{ ID: "1", url: "/chart-patterns/", title: "Chart Pattern Guide" },
					{
						ID: "2",
						url: "/best-screeners/stock-screener-for-chart-patterns/",
						title: "Chart Pattern Screener"
					},
					{ ID: "3", url: "/dip-and-rip-pattern/", title: "Dip and Rip Pattern" },
					{
						ID: "4",
						url: "/first-green-day-pattern/",
						title: "First Green Day"
					},
					{ ID: "5", url: "/abcd-pattern/", title: "ABCD Pattern" }
				]
			},
			{
				ID: "D",
				url: "#",
				title: "Research",
				children: [
					{
						ID: "1",
						url: "/robinhood-penny-stocks/",
						title: "Penny Stocks on Robinhood"
					},
					{
						ID: "2",
						url: "https://lp.stockstotrade.com/project-x/transcript/",
						title: "Tesla's Project X"
					},
					{ ID: "3", url: "/diluted-shares/", title: "Stock Dilution" },
					{
						ID: "4",
						url: "/best-screeners/stock-screener-with-technical-indicators/",
						title: "Screeners with Technical Indicators"
					},
					{
						ID: "5",
						url: "/pc-mac-whats-best-trading/",
						title: "Mac or Windows for Day Trading"
					},
					{
						ID: "6",
						url: "/most-successful-penny-stocks-history/",
						title: "Penny Stocks that Exploded"
					},
					{ ID: "7", url: "/float-checker/", title: "Stock Float Checker" },
					{
						ID: "8",
						url: "https://lp.stockstotrade.com/watchlist/",
						title: "Weekly Stock Watchlist"
					},
					{
						ID: "9",
						url: "/how-to-unlock-the-power-of-ai-in-your-trading/",
						title: "Using AI in Day Trading"
					}
				]
			}
		]
	},
	{
		ID: "5",
		url: "/about/",
		title: "About"
	}
];
const $route = useRoute();
const showMenu = ref(false);
const searchform = ref();
const searchInput = ref();
const isHome = ref(false);
const showSearch = ref(false);
const header = ref(false);
const showProgressBar = ref(false);
const scrollPosition = ref(0);

const onSearch = (e) => {
	if (e.key === "Escape") return (showSearch.value = false);

	if (e.key === "Enter") {
		const searchInput = document.getElementById("searchInput").value;

		// Check if the search query is not empty
		if (searchInput && searchInput.trim() !== "") {
			// Redirect to the search page with the query
			window.location.href = `/blog/?search=${encodeURIComponent(searchInput)}`;

			showSearch.value = false;
		}
	}
};

const toggleSearch = () => {
	console.log("EXAMPLE", showSearch.value);

	showSearch.value = !showSearch.value;
	// setTimeout(() => searchInput.value.focus(), 200);
};

const headerStyleToggler = () => {
	const { innerWidth, scrollY } = window;
	if (innerWidth > 1024 && scrollY > 700 && isHome.value) {
		isHome.value = false;
	} else if (scrollY > 900 && isHome.value) {
		isHome.value = false;
	} else if (scrollY <= 700 && !isHome.value) {
		isHome.value = true;
	}
};

const toggleHeaderClasses = () => {
	if (!header.value) return;

	const scrollY = window.scrollY;
	scrollPosition.value = scrollY;

	if (scrollY < 32) {
		header.value.classList.remove("has-progress-bar");
		header.value.style.marginTop = "-8px";
	} else {
		header.value.classList.add("has-progress-bar");
		header.value.style.marginTop = "8px";
	}
};

watch(
	[() => $route.name, header],
	([newPath, newHeader], [oldPath, oldHeader]) => {
		if (newPath != oldPath)
			window.removeEventListener("scroll", headerStyleToggler);

		if (process.client && ["slug"].includes(newPath) && newHeader) {
			newHeader.style.marginTop = "-10px";
			showProgressBar.value = true;

			window.addEventListener("scroll", toggleHeaderClasses, {
				passive: true
			});
		}

		if (process.client && oldPath === "slug" && newHeader) {
			showProgressBar.value = false;
			newHeader.style.marginTop = "0";
			newHeader.classList.remove("has-progress-bar");
			window.removeEventListener("scroll", toggleHeaderClasses);
		}

		if (
			process.client &&
			["index", "blog", "platform", "about", "plans"].includes(newPath)
		) {
			isHome.value = true;
			window.addEventListener("scroll", headerStyleToggler);
		} else if (
			process.client &&
			!["index", "blog", "platform", "about", "plans"].includes(newPath)
		) {
			isHome.value = false;
		}
	}
);

onBeforeUnmount(() => {
	if (process.client && header.value) {
		window.removeEventListener("scroll", toggleHeaderClasses);
	}
});

onMounted(async () => {
	if (["index", "blog", "platform", "about", "plans"].includes($route.name)) {
		isHome.value = true;

		if (process.client) window.addEventListener("scroll", headerStyleToggler);
	} else if (
		!["index", "blog", "platform", "about", "plans"].includes($route.name)
	) {
		isHome.value = false;
	}

	if (["slug"].includes($route.name) && process.client) {
		if (header.value) {
			await setTimeout(() => {
				showProgressBar.value = true;

				window.addEventListener("scroll", toggleHeaderClasses, {
					passive: true
				});
			}, 200);
		}
	}
});
</script>

<template>
	<ReadingProgressBar v-if="showProgressBar && scrollPosition > 10" />
	<header
		id="header"
		ref="header"
		:class="{ 'is-home': isHome }"
	>
		<Container class="flex mx-auto items-center">
			<NuxtLink
				to="/"
				class="min-w-[166px]"
			>
				<Logo
					id="logo"
					width="166"
					height="26"
					alt="logo image"
					src="/images/sttlogo_left.svg"
				/>
			</NuxtLink>

			<Navbar :navigation="navigation" />

			<button
				aria-label="Search for post"
				class="h-[24px] w-[24px] px-0 py-0 ml-auto mr-[12px] xl:mx-[24px] search-btn"
				@click="toggleSearch"
			>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 25"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M10.0799 1.93994C5.56867 1.93994 1.91992 5.58869 1.91992 10.0999C1.91992 14.6112 5.56867 18.2599 10.0799 18.2599C11.6905 18.2599 13.1812 17.7874 14.4449 16.9849L20.3399 22.8799L22.3799 20.8399L16.5599 15.0349C17.6062 13.6624 18.2399 11.9618 18.2399 10.0999C18.2399 5.58869 14.5912 1.93994 10.0799 1.93994ZM10.0799 3.85994C13.5355 3.85994 16.3199 6.64432 16.3199 10.0999C16.3199 13.5556 13.5355 16.3399 10.0799 16.3399C6.6243 16.3399 3.83992 13.5556 3.83992 10.0999C3.83992 6.64432 6.6243 3.85994 10.0799 3.85994Z"
						fill="white"
					/>
				</svg>
			</button>

			<div class="relative xl:flex items-center ml-[12px] xl:mr-0 h-[32px] hidden">
				<button
					@click="showSignInMenu = true"
					class="hover:text-yellow-500"
				>
					<svg
						stroke="currentColor"
						fill="none"
						stroke-width="0"
						viewBox="0 0 24 24"
						height="24"
						width="24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9ZM14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z"
							fill="currentColor"
						></path>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 14.0902 3.71255 16.014 4.90798 17.5417C6.55245 15.3889 9.14627 14 12.0645 14C14.9448 14 17.5092 15.3531 19.1565 17.4583C20.313 15.9443 21 14.0524 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM12 21C9.84977 21 7.87565 20.2459 6.32767 18.9878C7.59352 17.1812 9.69106 16 12.0645 16C14.4084 16 16.4833 17.1521 17.7538 18.9209C16.1939 20.2191 14.1881 21 12 21Z"
							fill="currentColor"
						></path>
					</svg>
				</button>

				<div
					v-if="showSignInMenu"
					class="absolute right-0 top-[8px] pt-[32px]"
					@mouseleave="showSignInMenu = false"
				>
					<div
						class="rounded-[12px] border-slate-200 border-solid border-[1px] pt-[8px] px-[16px] w-[200px] pb-[12px] bg-white flex flex-col"
					>
						<NuxtLink
							class="text-slate-700 py-[8px] hover:text-yellow-500"
							href="https://university.stockstotrade.com/login"
							target="_blank"
						>
							University
						</NuxtLink>
						<NuxtLink
							class="text-slate-700 py-[8px] hover:text-yellow-500"
							href="https://orders.stockstotrade.com/cp/sign_in"
							target="_blank"
						>
							Login
						</NuxtLink>
					</div>
				</div>
			</div>

			<watch-live
				:isVisible="true"
				class="watch-live-stt-header"
			/>

			<button
				class="flex h-[32px] w-[32px] justify-center items-center xl:hidden"
				@click="showMenu = true"
			>
				<svg
					width="24"
					height="25"
					viewBox="0 0 24 25"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<line
						x1="24"
						y1="7.25"
						x2="-7.86805e-08"
						y2="7.25"
						stroke="white"
						stroke-width="1.5"
					/>
					<line
						x1="24"
						y1="19.25"
						x2="7"
						y2="19.25"
						stroke="white"
						stroke-width="1.5"
					/>
				</svg>
			</button>
		</Container>
	</header>

	<Transition
		name="slide-left"
		:duration="{ enter: 250, leave: 250 }"
	>
		<mobile-menu
			v-if="showMenu"
			:navigation="navigation"
			:toggle="() => (showMenu = false)"
		/>
	</Transition>
	<Transition name="fade">
		<section
			v-if="showSearch"
			id="search-container"
		>
			<GradientBorderBlock
				ref="searchform"
				id="searchform"
				:parent-class="`search-bar ${
					showSearch ? 'active focused-gradient' : ''
				} max-w-[800px] `"
				class="flex items-center"
			>
				<input
					id="searchInput"
					ref="searchInput"
					type="text"
					name="q"
					placeholder="Search for an article"
					class="search-field"
					@keyup="onSearch"
				/>

				<button
					type="submit"
					class="mb-0 px-0 py-0 h-[24px] w-[24px] absolute right-[20px]"
					@click="onSearch"
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 25"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M10.0799 1.93994C5.56867 1.93994 1.91992 5.58869 1.91992 10.0999C1.91992 14.6112 5.56867 18.2599 10.0799 18.2599C11.6905 18.2599 13.1812 17.7874 14.4449 16.9849L20.3399 22.8799L22.3799 20.8399L16.5599 15.0349C17.6062 13.6624 18.2399 11.9618 18.2399 10.0999C18.2399 5.58869 14.5912 1.93994 10.0799 1.93994ZM10.0799 3.85994C13.5355 3.85994 16.3199 6.64432 16.3199 10.0999C16.3199 13.5556 13.5355 16.3399 10.0799 16.3399C6.6243 16.3399 3.83992 13.5556 3.83992 10.0999C3.83992 6.64432 6.6243 3.85994 10.0799 3.85994Z"
							fill="white"
						/>
					</svg>
				</button>
			</GradientBorderBlock>
			<div
				class="overlay"
				@click="toggleSearch"
			/>
		</section>
	</Transition>
</template>

<style lang="scss" scoped>
#header {
	padding: 16px 0;
	position: sticky;
	top: 0;
	z-index: 99;
	background: linear-gradient(
		54deg,
		rgb(5 41 143) 8.05%,
		rgb(55 0 211 / 98%) 91.58%
	);
	// background: linear-gradient(90deg, #091d56 10%, rgb(56, 21, 137));
	/*&.is-home {
		background: linear-gradient(
			180deg,
			rgba(9, 13, 24, 0.4) 0%,
			rgba(9, 13, 24, 0) 90%
		);
	}*/
	@include breakpoint(xl) {
		background: linear-gradient(
			110deg,
			rgb(11 45 143 / 99%) 8.05%,
			rgb(63 14 202 / 98%) 91.58%
		);
	}

	&.has-progress-bar {
		top: 7px;
	}
}
#logo {
	max-width: 166px;
	width: 100%;
	left: -4px;
	position: relative;
}

#search-container {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	margin: auto;
	backdrop-filter: blur(2px);
	background-color: rgba($slate-900, 0.2);
	padding: 20px;
	z-index: 99;
}
.search-bar {
	background: rgba(56, 79, 140, 0.1);
	width: 100%;
	margin: auto;
	left: 0;
	right: 0;
	top: 120px;
	@include breakpoint(md) {
		position: fixed;
	}
}

.search-field {
	background-color: $slate-900;
	width: 100%;
	height: 56px;
	padding: 12px 20px;
	font-weight: 400;
	letter-spacing: 0.5px;
	color: $slate-25;
	&::placeholder {
		color: $slate-300;
	}
}
</style>
