<template>
	<Row class="w-full overflow-hidden">
		<Container class="px-[20px] lg:px-0">
			<h2
				id="stay-ahead-title"
				class="text-white text-[32px] lg:text-6xl font-medium mb-[32px] text-center w-full max-w-[848px] text-center mx-auto"
			>
				Stay Ahead of the Curve with our AI and Algorithm-Enhanced Tools
			</h2>

			<p class="lg:text-center text-base text-slate-300 mb-[16px] lg:mb-[32px]">
				Not sure how to use the screener? Check out our quick guide.
			</p>

			<div class="inline-flex w-full justify-center mb-[36px]">
				<CtaButton
					type="secondary"
					to="https://cfn.stockstotrade.com/try-stt1"
					:blank="true"
					dataCtaType="try-platform"
				>
					Try StocksToTrade Platform
				</CtaButton>
			</div>

			<div
				id="stay-ahead-main-image"
				class="relative left-[64px]"
			>
				<img
					data-animate="true"
					width="1194"
					height="795"
					class="block mt-[36px] min-w-[500px] min-h-[368px]"
					src="/images/features/stockstrade-ui.webp"
					alt="images/features-1.webp"
					loading="lazy"
				/>

				<LazyTopGainerIframe v-if="showIframe" />
			</div>
		</Container>
	</Row>
	<Row>
		<Container class="px-[20px] lg:px-0">
			<div
				class="grid grid-cols-1 md:grid-cols-5 items-center gap-x-20 gap-x-16 md:gap-x-24 lg:gap-x-32 gap-y-[40px] md:gap-y-20 lg:gap-y-28"
			>
				<div
					id="feature-1"
					data-animate="true"
					class="md:col-span-2 order-1 place-self-center"
				>
					<FeatureCard
						:title="features[0].title"
						:icon="features[0].icon"
						:items="features[0].items"
					/>
				</div>
				<div
					class="relative md:col-span-3 order-2 place-self-center"
					data-animate="true"
					id="feature-img-1"
				>
					<video
						v-if="isDesktop"
						width="724"
						height="400"
						data-animate="true"
						loading="lazy"
						id="feature-img-1"
						class="rounded-[16px]"
						autoplay
						playsinline
						loop
						muted
					>
						<source
							src="/media/stt-video-1.webm"
							type="video/webm"
						/>

						<source
							src="/media/stt-video-1.mp4"
							type="video/mp4"
						/>
						Your browser does not support the video tag.
					</video>
					<video
						v-else
						width="724"
						height="400"
						data-animate="true"
						loading="lazy"
						id="feature-img-1"
						class="rounded-[16px]"
						autoplay
						playsinline
						loop
						muted
					>
						<source
							src="/media/mobile-stt-1_opt.webm"
							type="video/webm"
						/>
						<source
							src="/media/mobile-stt-1.mp4"
							type="video/mp4"
						/>
						Your browser does not support the video tag.
					</video>
				</div>

				<!-- <div
					class="md:col-span-3 order-4 md:order-3 md:hidden place-self-center relative w-full"
				>
					<img
						src="/images/features/s2p1.png"
						alt="Feature 2 Small Image 1"
						class="w-full h-auto rounded-lg block"
						loading="lazy"
					/>
				</div> -->
				<div
					data-animate="true"
					id="feature-img-2"
					class="md:col-span-3 order-4 md:order-3 grid-cols-1 gap-x-2 place-self-center relative"
				>
					<div class="block">
						<img
							height="697"
							width="662"
							src="/images/features/feature-image-2-hq.webp"
							alt="feature-image-2.webp"
							loading="lazy"
						/>
					</div>
					<div class="shadow-gradient shadow-gradient !bottom-0"></div>
				</div>
				<div
					id="feature-2"
					data-animate="true"
					class="feature-block md:col-span-2 order-3 md:order-4 place-self-center"
				>
					<FeatureCard
						:title="features[1].title"
						:icon="features[1].icon"
						:items="features[1].items"
					/>
				</div>

				<div
					id="feature-3"
					data-animate="true"
					class="feature-block md:col-span-2 order-5 place-self-center"
				>
					<FeatureCard
						:title="features[2].title"
						:icon="features[2].icon"
						:items="features[2].items"
					/>
				</div>
				<div
					class="md:col-span-3 order-6 place-self-center relative"
					data-animate="true"
					id="feature-img-3"
				>
					<img
						width="648"
						height="388"
						src="/images/features/feature-image-3-mockup-hq.webp"
						alt="Feature 3 MacBook Image"
						class="w-full max-w-[640px] h-auto"
						loading="lazy"
					/>
				</div>
			</div>
			<div class="flex justify-center mt-[48px] lg:mt-[96px]">
				<CtaButton
					type="secondary"
					button-class="!text-wrap justify-center !whitespace-normal"
					to="https://cfn.stockstotrade.com/try-stt1"
					:blank="true"
					dataCtaType="14-day-trial"
				>
					Get your 14-day trial of StocksToTrade now — only $7!
				</CtaButton>
			</div>
		</Container>
	</Row>
</template>

<script setup>
const showIframe = ref(false);
const isDesktop = ref(false);

const breakpoints = reactive(
	useBreakpoints({
		small: 768
	})
);

const features = [
	{
		title: "Smart Scan Solutions",
		icon: "/images/feature-stock.svg",
		items: [
			"Powerful scans, custom-built with penny-stock traders in mind.",
			"Thousands of complex search variables made beautifully simple.",
			"A custom-built trading algorithm, Oracle, alerts users to buy or sell stocks at specific prices."
		]
	},
	{
		title: "Powerful Research",
		icon: "/images/feature-growth-analysis.svg",
		items: [
			"Look-up financial reports, fundamentals and SEC filings on the main dashboard.",
			"Enjoy modern charting with drawing tools and countless indicators."
		]
	},
	{
		title: "Seamless Trading",
		icon: "/images/feature-growth.svg",
		items: [
			"Enjoy modern charting with drawing tools and countless indicators.",
			"Realistic paper-trading in sync with market data to provide accurate fills.",
			"Extremely fast level 1 data (option for level 2) across all major US markets."
		]
	}
];

onMounted(async () => {
	if (process.client) {
		await setTimeout(() => {
			showIframe.value = true;
		}, 3000);

		if (breakpoints.isGreater("small")) {
			isDesktop.value = true;
		}
	}
});
</script>

<style lang="scss" scoped>
.shadow-container {
	position: relative;
}

.shadow-gradient {
	position: absolute;
	left: 0;
	width: 100%;
	background: linear-gradient(180deg, rgba(10, 14, 24, 0) 0%, #0a0e18 90%);
	pointer-events: none; /* Ensures the gradient doesn't block interaction with elements below it */

	height: 200px; /* Adjust the height as needed */
}
</style>
