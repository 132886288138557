<template>
	<div
		class="bg-white rounded-[20px] py-[32px] px-[16px] text-center lg:text-left lg:p-[56px] flex flex-col-reverse lg:!pr-[240px] lg:!pb-[112px] relative"
	>
		<div class="flex flex-col gap-[32px]">
			<div class="gradient-title black">
				Get Daily, FREE Trade Alerts From Our Algorithm
			</div>

			<LeadCaptureForm
				id="sttwl-mainsite-bottom"
				class="max-w-[792px]"
				dataCtaType="footer-free-alerts"
			/>
			<JoinPitch id="join-the-team" />
		</div>

		<span class="chart-image mb-[32px] lg:mb-0">
			<ChartImage class="w-full lg:min-w-[896px]" />
		</span>
	</div>
</template>

<style scoped lang="scss">
.chart-image {
	@include breakpoint(lg) {
		position: absolute;
		bottom: 0;
		right: 40px;
		pointer-events: none;
	}
}
</style>
