<template>
	<iframe
		v-if="primary"
		title="top-gainers-iframe"
		src="https://develop.d2xx6vtf6inrnz.amplifyapp.com/small-grid"
		width="260"
		height="278"
		frameborder="0"
		loading="lazy"
	></iframe>

	<iframe
		v-else
		title="top-gainers-iframe-2"
		src="https://develop.d2xx6vtf6inrnz.amplifyapp.com/small-grid"
		width="300"
		height="372"
		frameborder="0"
		loading="lazy"
		class="absolute bottom-0 top-0 m-auto left-[-64px] rounded-[12px] md:rounded-[24px] border-slate-800 border-[3px] drop-shadow-xl"
	></iframe>
</template>

<script setup>
const { primary } = defineProps(["primary"]);
</script>

<style lang="scss">
iframe[title="top-gainers-iframe"] {
	background-image: url("/images/loading-mock.webp");
	height: 278px;
	background-size: cover;
	background-repeat: no-repeat;
	width: 260px;
}

[title="top-gainers-iframe-2"] {
	width: 200px;
	height: 236px;

	@include breakpoint(md) {
		background-image: url("/images/loading-mock.webp");
		width: auto;
		height: 372px;
	}
}
</style>
